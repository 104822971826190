<template>
    <div class="html-frame">

      <div v-if="numPages">
       <pdf 
        ref="pdf"
        :src="url"
        v-for="i in numPages" 
        :key="i"  
        :page="i"
        
      >
      </pdf>
      </div>
      <!-- <div v-else>
        <h1> 正在加载 PDF 文件，请耐心等待。 </h1>
        
      </div> -->
        <!-- <iframe id="hframe" :src="url"  frameborder="0" :style="'transform: translateX(0px); width:'+ width +'; height:' + height"></iframe>  -->
    </div>
</template>

<script>
import pdf from 'vue-pdf'

  
export default {
  name: 'HtmlFrame',

  props: {
    width:  {
      typeof: [String],
      default: "950px",
    },
    height:  {
      typeof: [String],
      default: "8500px",
    },
    url: {
      typeof: [String],
      require: true,
    },
  },
  
  components:{
      pdf
  },
  
  data() {
    return {
    //  url2: "/airesource/standard/1.pdf",
      numPages: null, // pdf 总页数s
    }
  },
   watch: {
    url: {
      immediate: true,
      handler(val) {
        console.log(" watch url " + val)
        this.getNumPages(val)
      },
    },
  },
  mounted() {
    console.log(" ======== url " + this.url)
    // const iframe = document.querySelector('#hframe')
    this.getNumPages(this.url)
  },
  methods: {
    //  计算pdf页码总数
    getNumPages(pdfUrl) {
      if(!pdfUrl) {
        console.log(" pdfUrl not define " + pdfUrl)
        return ;
      } 
      let loadingTask = pdf.createLoadingTask(pdfUrl)
      loadingTask.promise.then(pdf => {
        this.numPages = pdf.numPages
      })
      .catch(err => {
        console.error('pdf 加载失败', err);
      })
    },
  },
};
</script>

<style scoped lang="less">
  .html-frame {
    padding: 0px;
    width: 100%;
    // width: 1100px;
    height: 100%;
    // display: flex;
    // justify-content: center;
    // border: 1px solid  #0f0; 
    
  }

</style>
